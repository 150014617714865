import styled from 'styled-components'

import { buttonReset } from '~/styles/components/button'
import { typographyStyle } from '~/styles/utils'
import theme from '~/styles/theme'

export const Option = styled.button`
  ${buttonReset}
  ${typographyStyle('paragraph')}
  font-weight: bold;
  display: block;
  width: 100%;
  padding: 1.25rem 2.5rem;
  min-height: 5rem;
  border: 1px dashed ${props => props.active ? 'transparent' : 'rgba(0,0,0,0.5)'};
  border-radius: 2.5rem;
  margin-bottom: 1rem;
  background: ${props => props.active ? theme.colors[props.theme.bg] : 'transparent'};
  color: ${props => props.active ? theme.colors[props.theme.color] : 'black'};
  transition: all 150ms ease-in-out;
  transition-property: background, color;

  &:hover {
    background: ${props => props.active ? theme.colors[props.theme.bg] : `${theme.colors[props.theme.bg]}20`};
  }
`