import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { animated } from 'react-spring'

import RangeSlider from '~/components/RangeSlider'
import StepSlider from '~/components/StepSlider'
import MultipleChoice from '~/components/MultipleChoice'

import {
  Container,
  Heading,
  Spacer,
  Button,
  Icon
} from '~/styles/components'

const AnimatedWrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const InputComponent = ({ type, ...props }) => {
  if (type === 'range') {
    return <RangeSlider {...props} />
  }
  if (type === 'step') {
    const steps = ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree']
    return <StepSlider steps={steps} {...props} />
  }
  if (type === 'choice') {
    return <MultipleChoice {...props} />
  }
  return <div/>
}

InputComponent.propTypes = {
  type: PropTypes.string
}

const QuizQuestion = ({
  prevPage,
  type,
  heading,
  theme,
  progress,
  answer,
  onAnswerChange,
  numberOfQuestions,
  inputProps,
  animatedProps
}) => {
  return (
    <AnimatedWrapper style={animatedProps}>
      <Container>
        <Spacer size="md" />
        <Button plain onClick={prevPage}>
          <Icon
            name="arrow"
            transform="rotate(180deg)"
            size="2rem"
          />
        </Button>
        <Spacer size="sm" />
        <Heading level="4">
          <i>{progress + 1}/{numberOfQuestions}</i>
        </Heading>
        <Heading level="3">
          {heading}
        </Heading>
        <Spacer size={{ _: 'sm', sm: 'md' }} />
        <InputComponent
          type={type}
          theme={theme}
          value={answer}
          onChange={onAnswerChange}
          
          {...inputProps}
        />
        <Spacer size={{ _: 'md', sm: 'xxs' }} />
        <Spacer size={{ _: 'lg', sm: 'xl' }} />
      </Container>
    </AnimatedWrapper>
  )
}

QuizQuestion.propTypes = {
  prevPage: PropTypes.func.isRequired,
  type: PropTypes.string,
  heading: PropTypes.string,
  theme: PropTypes.shape({
    bg: PropTypes.string,
    color: PropTypes.string,
  }),
  progress: PropTypes.number,
  answer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onAnswerChange: PropTypes.func.isRequired,
  numberOfQuestions: PropTypes.number,
  inputProps: PropTypes.object,
  animatedProps: PropTypes.object
}

export default QuizQuestion