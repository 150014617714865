import React, { useContext } from 'react'

import { StoreContext } from '~/store'

import {
  Box,
  Container,
  Markup,
  Spacer,
  Button,
  Icon
} from '~/styles/components'

const QuizIntro = ({ heading, subheading, body }) => {
  const { updateStarted } = useContext(StoreContext)

  const startQuiz = () => updateStarted(true)

  return (
    <Container>
      <Spacer size="md" />
      <Box maxWidth={{ _: '300px', sm: '400px', lg: '500px' }}>
        <Markup
          role="heading"
          aria-level="1"
          typography="headingExtraLarge"
          html={heading}
        />
        <Markup
          role="heading"
          aria-level="2"
          typography="headingLarge"
          html={subheading}
        />
      </Box>
      <Spacer size="sm" />
      <Markup
        typography="paragraph"
        html={body}
        maxWidth={{ _: '400px', lg: '600px' }}
      />
      <Spacer size="sm" />
      <Button
        onClick={startQuiz}
        shadow
        large
      >
        Take the quiz
        <Icon name="arrow" marginLeft="4rem" />
      </Button>
      <Spacer size="lg" />
    </Container>
  )
}

export default QuizIntro