export const evaluateProfile = (answers) => {
  const {
    power1,
    security1,
    freedom1,
    love1,
    power2,
    security2,
    love2,
    freedom2
  } = answers

  const weights = {
    power: [
      { value: power1, weight: 1 },
      { value: power2, weight: 1 }
    ],
    love: [
      { value: love1, weight: 1 },
      { value: love2, weight: 1 }
    ],
    security: [
      { value: security1, weight: 1 },
      { value: security2, weight: 1 }
    ],
    freedom: [
      { value: freedom1, weight: 1 },
      { value: freedom2, weight: 1 }
    ],
  }

  const calculateScore = (key) => {
    return weights[key].reduce((score, { value, weight }) => {
      return score + ((value / 2 - 1) * weight)
    }, 0)
  }

  const scores = {
    power: calculateScore('power'),
    love: calculateScore('love'),
    security: calculateScore('security'),
    freedom: calculateScore('freedom'),
  }

  return Object.keys(scores).reduce((profile, result) => {
    if (!!profile) {
      return scores[result] > scores[profile] ? result : profile
    }
    return result
  }, null)
}