import React, { useContext } from 'react'
import { graphql } from 'gatsby';

import { StoreContext } from '~/store'

import Metadata from '~/components/Metadata'
import QuizIntro from './components/QuizIntro'
import QuizForm from './components/QuizForm'

import { Box } from '~/styles/components'

import { mapCraft } from './mappings'

const QuizTemplate = ({ data }) => {
  const { entry } = mapCraft(data.craft)
  const { started, loading } = useContext(StoreContext)

  return (
    <>
      <Metadata
        title="Quiz"
        {...entry.metadata}
      />
      {!loading && started && <QuizForm />}
      {!loading && !started && <QuizIntro {...entry.introBlock} />}
      {loading &&
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          typography="headingMedium"
        >
          Loading quiz...
        </Box>
      }
    </>
  )
}

export default QuizTemplate

export const query = graphql`
query QuizTemplateQuery($id: Craft_QueryArgument) {
  craft {
    entry(id: [$id]) {
      ... on Craft_quiz_quiz_Entry {
        metadata {
          ... on Craft_metadata_BlockType {
            title: metadataTitle
            description: metadataDescription
            keywords: metadataKeywords
            noFollow: metadataNoFollow
            noIndex: metadataNoIndex
          }
        }
        introBlock: quizIntroBlock {
          ... on Craft_quizIntroBlock_BlockType {
            heading
            subheading
            body
          }
        }
      }
    }
  }
}
`