
export const questions = {
  age: {
    heading: 'How old are you?',
    type: 'range',
    inputProps: {
      min: 17,
      max: 40
    },
    theme: {
      bg: 'yellow',
      color: 'black'
    }
  },
  gender: {
    key: 'gender',
    heading: 'Which of the following best describes your gender?',
    type: 'choice',
    inputProps: {
      choices: [
        { label: 'Female', value: 'Female' },
        { label: 'Male', value: 'Male' },
        { label: 'Non-binary', value: 'Non-binary' },
        { label: 'Gender non conforming', value: 'Gender non conforming' },
        { label: 'Trans female', value: 'Trans female' },
        { label: 'Trans male', value: 'Trans male' },
        { label: 'Not listed', value: 'Not listed' },
        { label: 'Prefer not to say', value: 'Prefer not to say' }
      ]
    },
    theme: {
      bg: 'purple',
      color: 'white'
    }
  },
  relationshipStatus: {
    heading: 'What is your current relationship status?',
    type: 'choice',
    inputProps: {
      choices: [
        { label: 'Single', value: 'Single' },
        { label: 'In a relationship but not living together', value: 'Not living together' },
        { label: 'In a relationship and living together', value: 'Living together' },
        { label: 'Married or defacto', value: 'Married' },
        { label: 'Divorced or seperated', value: 'Divorced' },
        { label: 'Widowed', value: 'Widowed' },
        { label: 'Something else', value: 'Something else' }
      ]
    },
    theme: {
      bg: 'purple',
      color: 'white'
    }
  },
  power1: {
    heading: 'Money is important because it shows how successful and powerful you are.',
    type: 'step',
    inputProps: {},
    theme: {
      bg: 'green',
      color: 'white'
    }
  },
  security1: {
    heading: 'I would rather save money than spend it.',
    type: 'step',
    inputProps: {},
    theme: {
      bg: 'green',
      color: 'white'
    }
  },
  freedom1: {
    heading: 'With enough money you can do whatever you want.',
    type: 'step',
    inputProps: {},
    theme: {
      bg: 'green',
      color: 'white'
    }
  },
  love1: {
    heading: 'I often demonstrate my love to people by buying them things.',
    type: 'step',
    inputProps: {},
    theme: {
      bg: 'green',
      color: 'white'
    }
  },
  power2: {
    heading: 'The best thing about money is that it gives you the power to influence others.',
    type: 'step',
    inputProps: {},
    theme: {
      bg: 'green',
      color: 'white'
    }
  },
  security2: {
    heading: 'It is important to have savings, you never know when you may urgently need the money.',
    type: 'step',
    inputProps: {},
    theme: {
      bg: 'green',
      color: 'white'
    }
  },
  love2: {
    heading: 'I am very generous with the people I love.',
    type: 'step',
    inputProps: {},
    theme: {
      bg: 'green',
      color: 'white'
    }
  },
  freedom2: {
    heading: 'The main point of earning money is to feel free and be free.',
    type: 'step',
    inputProps: {},
    theme: {
      bg: 'green',
      color: 'white'
    }
  }
}