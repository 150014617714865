import styled from 'styled-components'
import ReactSlider from 'react-slider'

import theme from '~/styles/theme'
import { typographyStyle } from '~/styles/utils'

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 4px;
  margin-top: 9.5rem;
  margin-bottom: 1.5rem;
`

export const StyledThumb = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background: ${props => theme.colors[props.bg]};
  border: 2px solid white;
  cursor: grab;
  top: 50%;
  transform: translateY(-50%);

  &:focus {
    outline: none;
  }

  &:before,
  &:after {
    position: absolute;
    top: -2rem;
    left: 50%;
    width: 6rem;
    height: 6rem;
  }

  &:before {
    content: '';
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    border-top-right-radius: 3rem;
    transform: translate(-50%, -100%) rotate(45deg);
    background: ${props => theme.colors[props.bg]};
  }

  &:after {
    content: '${props => props.label}';
    transform: translate(-50%, -100%);
    color: ${props => theme.colors[props.color]};
    display: flex;
    align-items: center;
    justify-content: center;
    ${typographyStyle('headingSmall')}
    margin: 0;
  }
`

export const StyledTrack = styled.div`
  background: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 2px;
`