import React from 'react'
import PropTypes from 'prop-types'

import { StyledSlider, StyledThumb, StyledTrack } from './style'

const RangeSlider = ({
  theme = {
    bg: 'black',
    color: 'white'
  },
  min,
  max,
  value,
  onChange
}) => {

  const Thumb = (props, state) => {
    let label = state.value
    if (state.value === min) {
      label = `<${state.value}`;
    }
    if (state.value === max) {
      label = `${state.value}+`
    }
    return (
      <StyledThumb
        {...props}
        label={label}
        {...theme}
      />
    );
  }
  const Track = (props, state) =>
    <StyledTrack
      {...props}
      index={state.index}
    />

  return (
    <StyledSlider
      min={min}
      max={max}
      value={value}
      onChange={onChange}
      renderTrack={Track}
      renderThumb={Thumb}
    />
  )
}

RangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  theme: PropTypes.shape({
    bg: PropTypes.string,
    color: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired
}

export default RangeSlider