import React, { useState, useContext } from 'react'
import { navigate } from 'gatsby'
import { useTransition } from 'react-spring'

import { StoreContext } from '~/store'

import ProgressBar from '~/components/ProgressBar'
import QuizQuestion from './QuizQuestion'

import { Box, Container, Button, Icon } from '~/styles/components'

import { questions } from '../data'
import { submitQuizResult } from '~/utils/firebase-client'
import { evaluateProfile } from '~/utils/profile-evaluator'

const QuizForm = () => {
  const {
    answers,
    progress,
    updateProgress,
    updateAnswer,
    updateStarted,
    resetStore
  } = useContext(StoreContext)

  const [direction, setDirection] = useState(1)
  const transitions = useTransition(progress, p => p, {
    from:  { opacity: 0, transform: `translate3d(${direction * 100}%,0,0)` },
    enter: { opacity: 1, transform: `translate3d(0%,0,0)` },
    leave: { opacity: 0, transform: `translate3d(${direction * -50}%,0,0)` },
  })

  const numberOfQuestions = Object.keys(questions).length
  const isFirst = progress === 0
  const isLast = progress === numberOfQuestions - 1
  const currentAnswer = answers[Object.keys(questions)[progress]]
  const currentQuestion = questions[Object.keys(questions)[progress]]
  const { theme } = currentQuestion

  const finishQuiz = () => {
    const profile = evaluateProfile(answers)
    submitQuizResult({ answers, profile })
      .then(({ id }) => {
        navigate(`/quiz/result?id=${id}`)
        resetStore()
      })
  }

  const nextPage = () => {
    if (isLast) {
      finishQuiz()
    } else {
      updateProgress(progress + 1)
      setDirection(1)
    }
  }
  const prevPage = () => {
    if (isFirst) {
      updateStarted(false)
    } else {
      updateProgress(progress - 1)
      setDirection(-1)
    }
  }

  const pages = transitions.map(({ item, props, key }) => {
    const questionKey = Object.keys(questions)[item]
    const onAnswerChange = (value) => updateAnswer(questionKey, value)
    const pageProps = {
      ...questions[questionKey],
      prevPage,
      progress: item,
      numberOfQuestions,
      answer: answers[questionKey],
      onAnswerChange,
      animatedProps: props
    }
    return <QuizQuestion key={key} {...pageProps} />
  })

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <ProgressBar
        theme={theme}
        value={(progress + 1) / (numberOfQuestions + 1)}
      />
      <Box flex="1 1" position="relative" overflowY="auto">
        {pages}
      </Box>
      {currentAnswer !== null &&
        <Box
          position="fixed"
          left="0"
          right="0"
          bottom="0"
          bg="cream"
          color="cream"
          paddingY={{ _: '1.5rem', lg: '3rem' }}
          zIndex="1"
          boxShadow={{
            _: '0 -15px 15px 5px currentColor',
            lg: '0 -20px 20px 10px currentColor',
          }}
          pointerEvents="none"
        >
          <Container
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              bg={theme.bg}
              color={theme.color}
              pointerEvents="all"
              onClick={nextPage}
            >
              {isLast ? 'Finish' : 'Next'}
              <Icon name="arrow" marginLeft="4rem" />
            </Button>
          </Container>
        </Box>
      }
    </Box>
  )
}

export default QuizForm