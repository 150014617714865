import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '~/styles/components'

const ProgressBar = ({
  value,
  theme = {
    bg: 'black',
    color: 'white'
  },
}) => {
  return (
    <Box
      width="100%"
      height="1.5rem"
      bg="rgba(255,255,255,0.5)"
    >
      <Box
        width={100 * value + '%'}
        height="100%"
        bg={theme.bg}
        borderTopRightRadius=".75rem"
        borderBottomRightRadius=".75rem"
        transition="all 300ms ease-in-out"
      >

      </Box>
    </Box>
  )
}

ProgressBar.propTypes = {
  value: PropTypes.number,
  theme: PropTypes.shape({
    bg: PropTypes.string,
    color: PropTypes.string,
  }),
}

export default ProgressBar