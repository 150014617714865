import { only } from '~/utils/mapping-filters.js'

const mapEntry = (entry) => {
  return {
    metadata: only(entry.metadata),
    introBlock: only(entry.introBlock)
  }
}

export const mapCraft = (craft) => {
  return {
    entry: mapEntry(craft.entry)
  }
}