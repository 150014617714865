import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '~/styles/components'

import { StyledSlider, StyledThumb, StyledTrack, TrackBump } from './style'

const StepSlider = ({
  theme = {
    bg: 'black',
    color: 'white'
  },
  steps,
  value,
  onChange
}) => {
  const min = 0
  const max = steps.length - 1

  const Thumb = (props, state) =>
    <StyledThumb
      {...props}
      first={state.valueNow === min}
      last={state.valueNow === max}
      label={steps[state.valueNow]}
      {...theme}
    />
  const Track = (props, state) =>
    state.index > 0 ? <div/> :
      <StyledTrack {...props}>
        {steps.map((...[, i]) => <TrackBump key={i} position={i / max} />)}
      </StyledTrack>

  return (
    <Box margin="0 -1.25rem">
      <StyledSlider
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        renderTrack={Track}
        renderThumb={Thumb}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        padding="2rem 1.25rem 0"
        fontSize={{ _: '15px', sm: '18px' }}
        fontWeight="bold"
      >
        <Box width="25%">{steps[min]}</Box>
        <Box width="25%" textAlign="right">{steps[max]}</Box>
      </Box>
    </Box>
  )
}

StepSlider.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    bg: PropTypes.string,
    color: PropTypes.string,
  }),
}

export default StepSlider