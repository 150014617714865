import React from 'react'
import PropTypes from 'prop-types'

import { Option } from './style'

const MultipleChoice = ({
  choices,
  theme = {
    bg: 'black',
    color: 'white'
  },
  value,
  onChange
}) => {
  return (
    <div>
      {choices.map((choice, i) =>
        <Option
          key={i}
          active={choice.value === value}
          onClick={() => onChange(choice.value)}
          theme={theme}
        >
          {choice.label}
        </Option>
      )}
    </div>
  )
}

MultipleChoice.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  theme: PropTypes.shape({
    bg: PropTypes.string,
    color: PropTypes.string,
  }),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default MultipleChoice