import styled from 'styled-components'
import ReactSlider from 'react-slider'

import theme from '~/styles/theme'
import { typographyStyle } from '~/styles/utils'

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 4px;
  margin-top: 7.25rem;
  margin-bottom: 1.5rem;
`

export const StyledThumb = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background: ${props => theme.colors[props.bg]};
  border: 2px solid white;
  cursor: grab;
  top: 50%;
  transform: translateY(-50%);

  &:before {
    content: '${props => props.label}';
    position: absolute;
    top: -1.5rem;
    ${props => props.first ? 'left: -0.5rem;' :
      props.last ? 'right: -0.5rem;' :
      'left: 50%;'
    }
    transform: ${props => props.first || props.last ? 'translateY(-100%)' : 'translate(-50%, -100%)'};
    background: ${props => theme.colors[props.bg]};
    color: ${props => theme.colors[props.color]};
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    padding: 1rem 1.5rem;
    min-width: 8rem;
    border-radius: 8px;
    ${typographyStyle('paragraph')}
    font-weight: bold;
    margin: 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: -1.5rem;
    left: 50%;
    width: 1rem;
    height: 1rem;
    transform: translate(-50%, -50%) rotate(45deg);
    background: ${props => theme.colors[props.bg]};
  }
`

export const StyledTrack = styled.div`
  background: white;
  left: 0 !important;
  right: 0 !important;
  top: 0;
  bottom: 0;
  border-radius: 2px;
  margin-left: 1.75rem;
  margin-right: 1.75rem;
`

export const TrackBump = styled.div`
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  left: calc(100% * ${props => props.position} - 0.5rem);
  transform: translateY(-50%);
  border-radius: 50%;
  background: white;
`